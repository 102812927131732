import React, { Component } from "react";
import ApiHandlerHandler from "../../ApiHandler.js";
import config from "config";
import AnimeDetails from "./AnimeDetails.js";

import DocumentMeta from "react-document-meta";

import Error404 from "../errors/404/404.js";

class Anime extends Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.anime_slug = this.props.match.params.slug;
	}

	componentDidMount() {

		ApiHandlerHandler
			.get(`/anime/slug/${this.anime_slug}`)
			.then(d => {
				this.setState({ anime: d });
				if (!d) {
					this.setState({
						e404: true
					})
				}
			});

	}

	render() {

		if (this.state && this.state.e404)
            return <Error404/>;

		if (true) {
			const { anime } = this.state || {};

			const meta = {
				// SEO spot
				title: `${anime ? anime.name : "HiAnime.to"} - Watch Anime Online English Subbed/Dubbed - ${config.website.name}`,
			    // SEO spot
				description: `${anime ? anime.synopsis : ""}`,
			    meta: {
			        charset: "utf-8",
			        name: {
						// SEO spot
			            keywords: `Aniwatch.to Free Anime, Watch Free Anime, Anime Stream, Watch Online Anime, AnimeKisa, KissAnime, Animeheaven.eu, Anilinkz, AnimeWatch, Anime Stream`
			        }
			    }
			
			}

 			return (
					<div id="details" className="desktop-view">
						<DocumentMeta {...meta} />
						<AnimeDetails animeSlug={this.anime_slug} anime={anime} />
					</div>
			)
		}

		return (
			<div className="loading">
		    <div class="spinner"><div></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>
		</div>
		)

	}


}

export default Anime;
